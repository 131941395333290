import React, {useCallback, useContext, useEffect, useState} from "react";
import PaymentResultModalContext from "./PaymentResultModalContext";
import WindowLocationContext from "../window/WindowLocationContext";



const PaymentResultModalProvider = (
    {
        children
    }
) => {
    const windowLocationContext = useContext(WindowLocationContext);

    const [paymentResultModal, setPaymentResultModal] = useState(false);
    const [isSuccess, setSucces] = useState(true);

    useEffect(() => {
        if (windowLocationContext.search.includes("paymentSuccess=true")) {
            handleOpen();
            setSucces(true);
        }
        if (windowLocationContext.search.includes("paymentCanceled=true")) {
            handleOpen();
            setSucces(false);
        }
    }, [windowLocationContext.search]);

    const handleOpen = useCallback(()=>{
        setPaymentResultModal(true);
    }, []);

    const handleClose = useCallback(()=>{
        if (isSuccess) {
            windowLocationContext.removeQueryParameters("paymentSuccess");
        } else {
            windowLocationContext.removeQueryParameters("paymentCanceled");
        }

        setPaymentResultModal(false);
    }, [isSuccess]);

    return (
        <PaymentResultModalContext.Provider value={{
            isOpen: paymentResultModal,
            open: handleOpen,
            close: handleClose
        }}>
            {children}
            {
                paymentResultModal && (
                    <div className="modal fade show main-validation-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={handleClose}>
                        <div className="modal-dialog modal-dialog-scrollable"
                             style={{maxWidth: "750px"}}
                             onClick={(e)=>e.stopPropagation()}
                        >
                            <div className="modal-content main-validation-modal-content" style={{}}>
                                <div className="modal-body">
                                    <div>
                                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                                            {isSuccess &&
                                                <h2>
                                                    Váše platba proběhla <span>úspěšně!</span>
                                                </h2>
                                            }
                                            {!isSuccess &&
                                                <h2>
                                                    Vaše platba bohužel <span>neproběhla</span>.
                                                </h2>
                                            }
                                        </div>
                                    </div>

                                    <br/>

                                    {isSuccess &&
                                        <div className="service_section">
                                            <div className="service_text" data-aos-duration={1500} style={{paddingRight: "0px", textAlign: "center"}}>
                                                    <h2>
                                                        Za celý tým <span>melbe</span> bychom Vám chtěli poděkovat za <span>důvěru</span>.
                                                    </h2>
                                            </div>
                                        </div>
                                    }
                                    {isSuccess &&
                                        <div className="service_section">
                                            <div className="service_text" data-aos-duration={1500} style={{paddingRight: "0px", textAlign: "center"}}>
                                                <h2>
                                                    Vaše <span>členství na 12 měsíců</span> začne platit, jakmile aplikaci <span>v létě</span> vydáme. <br/> O všem Vás v čas <span>informujeme</span>.
                                                </h2>
                                                <div className="btn_block aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" style={{marginTop: "0px"}}>
                                                    <a className="btn puprple_btn ml-0" onClick={handleClose} >
                                                        Dokončit
                                                    </a>
                                                    <div className="btn_bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {!isSuccess &&
                                        <div className="service_section">
                                            <div className="service_text" data-aos-duration={1500} style={{paddingRight: "0px", textAlign: "center"}}>
                                                <h2>
                                                    Pokud máte zájem o <span>členství na 12 měsíců</span> v předprodeji, zkuste to, prosím, znovu.
                                                </h2>
                                                <div className="btn_block aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" style={{marginTop: "0px"}}>
                                                    <form action="/api/create-checkout-session" method="POST">
                                                        <button type="submit" className="btn puprple_btn ml-0">
                                                            Zkusit koupit členství znovu
                                                        </button>
                                                        <div className="btn_bottom" />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <br/>

                                    <div style={{textAlign: "right", width: "100%"}}>
                                        <div className="btn-link" onClick={handleClose} style={{color: "black", cursor: "pointer"}}>
                                            Zavřít
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </PaymentResultModalContext.Provider>
    )
};

export default PaymentResultModalProvider;

