import React, {useContext, useEffect, useState} from 'react'
import Header from '../Header/Main'
import Banner from '../Menhome/Banner'
import Keyfeature from '../Menhome/Keyfeature'
import Aboutus from '../Menhome/Aboutus'
import TextList from '../Menhome/TextList'
import Service from '../Menhome/Service'
import HowWork from '../Menhome/HowWork'
import Review from '../Menhome/Review'
import Clients from '../Menhome/Clients'
import Pricing from '../Menhome/Pricing'
import Interface from '../Menhome/Interface'
import Text from '../Menhome/Text'
import Blog from '../Menhome/Blog'
import Footer from '../Footer/Main'
import Aos from 'aos'
import QuestionModalProvider from "../../Utils/questionModal/QuestionModalProvider";
import WindowLocationProvider from "../../Utils/window/WindowLocationProvider";
import PaymentResultModalProvider from "../../Utils/paymenResultModal/PaymentResultModalProvider";

function Main() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);


    return (
        <WindowLocationProvider>
            <QuestionModalProvider>
                <PaymentResultModalProvider>
                    <div id="home_section"></div>
                    <Header />
                    <Banner />
                    <Keyfeature />
                    <div className="page_wrapper">
                        <Aboutus />
                        <TextList />
                        <Service />
                        <HowWork />
                        {/*<Review />*/}
                        <Clients />
                        <Pricing />
                        <Interface />
                        <Text />
                        {/*<Blog />*/}
                        <Footer />
                    </div>
                </PaymentResultModalProvider>
            </QuestionModalProvider>
        </WindowLocationProvider>
    )
}

export default Main