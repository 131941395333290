import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom'
import appscreen from '../../assets/images/appscreen.png'
import Application from '../../assets/images/applicationvideothumb.png'
import Playbtn from '../../assets/images/play_white.svg'
import Videobtn from '../../assets/images/playvideotext.png'
import Mobileicon from '../../assets/images/mobileicon.png'
import Usericon from '../../assets/images/usericon.png'
import Magneticon from '../../assets/images/magnet.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";


function Aboutus() {

    const [counts, setCounts] = useState({
        download: 0,
        reviews: 0,
        countries: 0,
        followers: 0,
    });

    const questionModalContext = useContext(QuestionModalContext);

    useEffect(() => {
        const updateCounter = () => {
            const targetCounts = {
                download: 500,
                reviews: 50,
                countries: 100,
                followers: 5,
            };

            const duration = 1500;
            const stepSize = 10;
            const steps = duration / stepSize;

            Object.keys(targetCounts).forEach((key) => {
                const targetCount = targetCounts[key];
                const currentCount = counts[key];
                const step = Math.ceil((targetCount - currentCount) / steps);

                if (step !== 0) {
                    const interval = setInterval(() => {
                        setCounts((prevCounts) => ({
                            ...prevCounts,
                            [key]: prevCounts[key] + step,
                        }));
                    }, stepSize);

                    setTimeout(() => {
                        clearInterval(interval);
                        setCounts((prevCounts) => ({
                            ...prevCounts,
                            [key]: targetCount,
                        }));
                    }, duration);
                }
            });
        };

        updateCounter();
    }, []);


    const [ytshow, setytShow] = useState(false);

    return (
        <>
            <section className="about_section row_am">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge mb-1">O Melbe</span>
                        <h2>
                            Jednoduchý{" "}
                            <img src={Mobileicon} /> <span> design</span>
                            {" "}přináší{" "}
                            <span>
                                <img src={Usericon} /> uživatelům
                            </span>
                            {" "}pohodlný uživatelský{" "}
                            <span>
                                <img src={Magneticon} /> zážitek
                            </span>.
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration={1500} >
                                <li data-aos="fade-up" data-aos-duration={1500}>
                                    <div className="text">
                                        <p>
                                            <span className="counter-value">{counts.download}</span>
                                            <span>+</span>
                                        </p>
                                        <p>Uživatelů</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-duration={1500}>
                                    <div className="text">
                                        <p>
                                            <span className="counter-value">{counts.reviews}</span>
                                            <span>+</span>
                                        </p>
                                        <p>Recenzí</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-duration={1500}>
                                    <div className="text">
                                        <p>
                                            <span className="counter-value">{counts.countries}</span>
                                            <span>+</span>
                                        </p>
                                        <p>Zemí</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up" data-aos-duration={1500}>
                                    <div className="text">
                                        <p>
                                            <span className="counter-value">{counts.followers}</span>
                                            <span>K+</span>
                                        </p>
                                        <p>Followerů</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="abtImg text-center" data-aos="fade-up" data-aos-duration={1500} >
                                <img src={appscreen} style={{maxWidth: "285px", width: "100%"}} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p data-aos="fade-up" data-aos-duration={1500}>
                                Téma zdraví a dlouhověkosti je velmi složité a často je problém se v něm zorientovat. Naši odborníci udělají tuto práci za vás. <br/>  <br/> Na vás pak už jenom čeká aplikace plná přehledně zpracovaných vědecky podložených informací, personalizovaných programů, které vás dovedou k vašemu cíli a sebereflexe, která vám zabere maximálně 2 minuty denně.
                            </p>
                            <div className="btn_block" data-aos="fade-up" data-aos-duration={1500}>
                                <Link  to={"#"}
                                       onClick={e => {
                                           e.preventDefault();
                                           questionModalContext.open("button09");
                                       }}
                                       className="btn puprple_btn ml-0">
                                    Chci aplikaci
                                </Link>
                                <div className="btn_bottom" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus