import React from 'react';

function PrivacyPolicyContent() {
    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-12">
                            <div className="banner_text">
                                <h1>Zásady ochrany <span>osobních údajů</span></h1>
                                <p>
                                    Aplikaci „Melbe“ (dále jen „aplikace“) vlastní a provozuje společnost DataSession s.r.o., IČ: 09598871, se sídlem Pod Pekařkou 107/1, Podolí, 147 00 Praha 4, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze pod sp. zn. C 338745 (dále jen „Melbe“, „my“ nebo „náš“). Použití aplikace a obsahu a informací dostupných v aplikaci (dále jen „služby“) podléhá přijetí a dodržování těchto zásad ochrany osobních údajů (dále jen „zásady“). Pojmy „vy“, „váš“, „uživatel“ a „uživatelé“ se vztahují na všechny uživatele aplikace.
                                </p>
                                <p>
                                    Vyhrazujeme si právo provádět změny těchto zásad kdykoli a z jakéhokoli důvodu. Tyto změny nabývají účinnosti dnem jejich zveřejnění (tedy dnem „Poslední aktualizace“ těchto zásad).
                                </p>
                            </div>


                            <br/>
                            <br/>
                            <div className="banner_text">
                                <h2>1. SHROMAŽĎOVÁNÍ OSOBNÍCH ÚDAJŮ</h2>
                                <p>
                                    1.1. Můžeme shromažďovat vaše osobní údaje různými způsoby. Informace, které můžeme shromažďovat prostřednictvím aplikace, zahrnují:
                                </p>
                                <ul>
                                    <li><strong>Osobní údaje:</strong> Křestní jméno, příjmení, přezdívka, emailová adresa, časové pásmo, a nahrané fotografie.</li>
                                    <li><strong>Odvozené údaje a nahraná média:</strong> Akce provedené při používání aplikace, nahraná média.</li>
                                    <li><strong>Povolení ze sociálních sítí:</strong> Informace z vašeho účtu na Facebooku nebo jiných sociálních sítích.</li>
                                    <li><strong>Údaje o mobilním zařízení:</strong> Informace o zařízení, jako je identifikační číslo, model, výrobce, verze OS, telefonní číslo a další.</li>
                                    <li><strong>Push oznámení:</strong> Upozornění týkající se vašeho účtu nebo aplikace.</li>
                                </ul>


                                <br/>
                                <h2>2. PRÁVNÍ DŮVOD, ÚČEL A DOBA ZPRACOVÁNÍ VAŠICH OSOBNÍCH ÚDAJŮ</h2>
                                <p>
                                    2.1. Osobní údaje zpracováváme na základě a v rámci plnění zákonných povinností, smluvního vztahu mezi námi, vámi uděleného souhlasu, ochrany zájmů subjektů údajů nebo jiné fyzické osoby, plnění úkolu prováděného ve veřejném zájmu a našich oprávněných zájmů.
                                </p>
                                <p>
                                    2.2. Vaše osobní údaje zpracováváme po dobu trvání smluvního vztahu mezi námi a po nezbytně nutnou dobu po jeho skončení.
                                </p>


                                <br/>
                                <h2>3. NAKLÁDÁNÍ S OSOBNÍMI ÚDAJI</h2>
                                <p>
                                    3.1. Nebudeme prodávat, distribuovat ani pronajímat vaše osobní údaje třetím stranám, které s námi nejsou propojené, pokud k tomu nemáme vaše svolení nebo pokud to nevyžaduje zákon. Vaše údaje mohou být zpracovávány externími dodavateli služeb na základě smlouvy o zpracování osobních údajů.
                                </p>
                                <p>
                                    3.2. Můžeme také zveřejnit vaše osobní údaje, abychom dodrželi jakýkoli soudní příkaz, zákon nebo právní proces, nebo pokud se domníváme, že je zveřejnění nezbytné k ochraně práv, majetku nebo bezpečnosti aplikace, našich zákazníků nebo jiných osob.
                                </p>


                                <br/>
                                <h2>4. TECHNOLOGIE SLEDOVÁNÍ</h2>
                                <p>
                                    4.1. Pokud nám k tomu dáte výslovný souhlas, můžeme používat soubory cookie, web beacons, sledovací pixely a další sledovací technologie, abychom přizpůsobili a vylepšili váš zážitek s aplikací.
                                </p>
                                <p>
                                    4.2. Můžeme také uzavřít partnerství s vybranými dodavateli třetích stran, jako jsou Google Analytics, abychom umožnili sledování a analýzu používání aplikace.
                                </p>


                                <br/>
                                <h2>5. NÁSTROJE TŘETÍCH STRAN</h2>
                                <p>
                                    5.1. Aplikace může obsahovat odkazy na webové stránky a aplikace třetích stran. Jakmile tyto odkazy použijete, nevztahují se tyto zásady na žádné informace, které poskytnete těmto třetím stranám, a nemůžeme zaručit bezpečnost a soukromí vašich osobních údajů.
                                </p>


                                <br/>
                                <h2>6. BEZPEČNOST VAŠICH INFORMACÍ</h2>
                                <p>
                                    6.1. K ochraně vašich osobních údajů používáme administrativní, technická a fyzická bezpečnostní opatření. Přesto však nemůžeme zaručit, že jakákoli metoda přenosu dat bude 100% bezpečná.
                                </p>


                                <br/>
                                <h2>7. ZVLÁŠTNÍ USTANOVENÍ TÝKAJÍCÍ SE OSOB MLADŠÍCH 15 LET</h2>
                                <p>
                                    7.1. Naše služba není určena pro děti mladší 15 let. Pokud zjistíme, že jsme shromáždili osobní údaje od dítěte mladšího 15 let bez souhlasu rodičů, tyto údaje vymažeme.
                                </p>


                                <br/>
                                <h2>8. MOŽNOSTI TÝKAJÍCÍ SE VAŠICH INFORMACÍ</h2>
                                <p>
                                    8.1. Máte právo přístup k vašim osobním údajům, jejich opravu nebo výmaz, omezení zpracování a právo vznést námitku proti zpracování. Můžete také odvolat svůj souhlas se zpracováním osobních údajů.
                                </p>


                                <br/>
                                <h2>9. KONTAKTUJTE NÁS</h2>
                                <p>
                                    9.1. V případě jakýchkoli problémů nás můžete kontaktovat pomocí následujících kontaktů:
                                </p>

                                <p>
                                    DataSession s.r.o.<br />
                                    <strong>Email:</strong> <a href="mailto:info@melbe.app">info@melbe.app</a><br />
                                    <strong>Adresa:</strong> Pod Pekařkou 107/1, Podolí, 147 00 Praha 4, Czech Republic
                                </p>
                                <p>
                                    <strong>Akutalizováno:</strong> 1.9. 2024
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicyContent;
