import React from 'react';
import { Link } from 'react-router-dom';

function TermsConditionsContent() {
    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-12">
                            <div className="banner_text">
                                <h1>
                                    Všeobecné podmínky užívání <span>aplikace Melbe</span>
                                </h1>
                                <p>
                                    Aplikaci „Melbe“ (dále jen „aplikace“) vlastní a provozuje společnost DataSession s.r.o., IČ: 09598871, se sídlem Pod Pekařkou 107/1, Podolí, 147 00 Praha 4, zapsaná v obchodním rejstříku vedeném Městským soudem v Praze pod sp. zn. C 338745 (dále jen „Melbe“, „my“ nebo „náš“). Použití aplikace a obsahu a informací dostupných v aplikaci (dále jen „služby“) podléhá přijetí a dodržování těchto zásad ochrany osobních údajů (dále jen „zásady“). Pojmy „vy“, „váš“, „uživatel“ a „uživatelé“ se vztahují na všechny uživatele aplikace.
                                </p>
                                <p>
                                    Používání Aplikace a obsah a informace dostupné v aplikaci (dále jen „Služby“) podléhají přijetí a dodržování podmínek stanovených v těchto všeobecných podmínkách užívání (dále jen „Podmínky“). Udělením výslovného souhlasu s těmito Podmínkami jste jimi vázáni v plném rozsahu a můžete začít užívat Aplikaci. Pokud s těmito Podmínkami nesouhlasíte, nejste oprávněni Aplikaci používat.
                                </p>
                            </div>

                            <br/>
                            <br/>

                            <div className="banner_text">
                                <h2>1. LICENCE K UŽÍVÁNÍ APLIKACE</h2>
                                <p>
                                    1.1. Jakmile zaplatíte příslušný poplatek (je-li Služba zpoplatněna), stáhnete a nainstalujete Aplikaci a úspěšně dokončíte proces registrace, budete moci Aplikaci užívat. Na základě zaplacení případných poplatků (je-li Služba zpoplatněna) a pod podmínkou, že dodržíte tyto Podmínky, vám bude poskytnuta omezená, nevýhradní a odvolatelná licence k užívání Aplikace, s výhradou omezení a restrikcí uvedených v těchto Podmínkách.
                                </p>

                                <h2>2. ÚČEL APLIKACE</h2>
                                <p>
                                    2.1. Aplikace Melbe je vytvořena za účelem osobního rozvoje uživatelů a slouží jako platforma pro dlouhověkost a zdravý životní styl. Aplikace analyzuje určité údaje uživatelů a poskytuje personalizovaná doporučení na základě těchto údajů.
                                </p>

                                <h2>3. REGISTRACE</h2>
                                <p>
                                    3.1. Aplikaci mohou používat pouze registrovaní uživatelé. Registrace se provádí prostřednictvím (i) účtu Google, (ii) účtu Apple ID. Změna registrovaných údajů je možná, s výjimkou uživatelského jména. V případě neplatných nebo neexistujících údajů jsme oprávněni příslušný účet vymazat.
                                </p>

                                <h2>4. PLATBY</h2>
                                <p>
                                    4.1. Používání Aplikace je zpoplatněno po skončením zkušební doby. Uživatel má možnost platit měsíční nebo roční poplatky prostřednictvím App Store nebo Google Play. Platby se automaticky obnovují, pokud je uživatel nezruší před začátkem nového platebního období.
                                </p>

                                <h2>5. VYLOUČENÍ A OMEZENÍ ODPOVĚDNOSTI</h2>
                                <p>
                                    5.1. Aplikace je poskytována "tak, jak je". Nezaručujeme dostupnost, přesnost nebo vhodnost Aplikace pro konkrétní účely.
                                </p>
                                <p>
                                    5.2. Neručíme za žádné přímé ani nepřímé škody související s užíváním Aplikace. Používání Aplikace je na vaše vlastní riziko.
                                </p>

                                <h2>6. DUŠEVNÍ VLASTNICTVÍ</h2>
                                <p>
                                    6.1. Všechna práva k duševnímu vlastnictví související s Aplikací a Službami náleží nám. Uživatelé nesmějí Aplikaci ani Služby reprodukovat, upravovat, distribuovat nebo jinak využívat mimo podmínky stanovené v těchto Podmínkách.
                                </p>

                                <h2>7. OSOBNÍ ÚDAJE</h2>
                                <p>
                                    7.1. Zpracování osobních údajů se řídí našimi zásadami ochrany osobních údajů. Používáním Aplikace souhlasíte se zpracováním osobních údajů v souladu s GDPR.
                                </p>

                                <h2>8. ODSTOUPENÍ OD SMLOUVY</h2>
                                <p>
                                    8.1. Akceptací těchto Podmínek uzavíráte smlouvu o dodání digitálního obsahu, který není dodán na hmotném nosiči. Při zahájení používání Aplikace se vzdáváte práva na odstoupení od smlouvy bez udání důvodu.
                                </p>

                                <h2>9. ZMĚNA PODMÍNEK</h2>
                                <p>
                                    9.1. Vyhrazujeme si právo kdykoliv upravit tyto Podmínky. Změny nabývají účinnosti zveřejněním na našich webových stránkách.
                                </p>

                                <h2>10. ROZHODNÉ PRÁVO A JURISDIKCE</h2>
                                <p>
                                    10.1. Tyto Podmínky se řídí zákony České republiky. Jakékoliv spory budou řešeny výhradně příslušnými soudy České republiky.
                                </p>

                                <h2>11. KONTAKTUJTE NÁS</h2>
                                <p>
                                    11.1. V případě jakýchkoli problémů nás můžete kontaktovat pomocí následujících kontaktů:
                                </p>
                                <p>
                                    DataSession s.r.o.<br />
                                    <strong>Email:</strong> <a href="mailto:info@melbe.app">info@melbe.app</a><br />
                                    <strong>Adresa:</strong> Pod Pekařkou 107/1, Podolí, 147 00 Praha 4, Czech Republic
                                </p>
                                <p>
                                    <strong>Akutalizováno:</strong> 1.9. 2024
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsConditionsContent;