import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/ft_logo.png'
import googleplay from '../../assets/images/googleplay.png'
import appstorebtn from '../../assets/images/appstorebtn.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";
import {handleClickContact, handleClickHome, handleClickPrice} from "../Header/Main";
import EmailForm from "../../Utils/forms/EmailForm";
import WindowLocationContext from "../../Utils/window/WindowLocationContext";

function Main() {
    const questionModalContext = useContext(QuestionModalContext);
    const windowLocationContext = useContext(WindowLocationContext);

    return (
        <>
            <footer className="white_text" data-aos="fade-in" data-aos-duration={1500} id="contact_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="logo_side">
                                <div className="logo">
                                    <Link to="#">
                                        <img src={logo} alt="Logo" />
                                    </Link>
                                </div>
                                <div className="news_letter">
                                    <h3>Zadejte email</h3>
                                    <p>Buďte mezi prvními, kdo se dozví o novinkách.</p>
                                    <EmailForm type="footerForm" note="S odesláním formuláře souhlasíte s našimi podmínkami." search={windowLocationContext.search} buttonId="buttonFORM" />
                                </div>
                                <ul className="contact_info">
                                    <li>
                                        <Link to="mailto:info@melbe.app">info@melbe.app</Link>
                                    </li>
                                    {/*<li>
                                        <Link to="tel:+420777111333">+420 777 111 333</Link>
                                    </li>*/}
                                </ul>
                                <ul className="social_media">
                                    <li>
                                        <Link to="https://www.facebook.com/profile.php?id=61552044385157" target="_blank">
                                            <i className="icofont-facebook"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://cz.linkedin.com/company/melbeapp" target="_blank">
                                            <i className="icofont-linkedin" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/lap_medic" target="_blank">
                                            <i className="icofont-instagram" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="download_side">
                                <h3>Stáhnout aplikaci</h3>
                                <ul className="app_btn">
                                    <li>
                                        <Link   to={"#"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    questionModalContext.open("button13");
                                                }}
                                        >
                                            <img className="blue_img" src={googleplay} alt="image" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link   to={"#"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    questionModalContext.open("button14");
                                                }}
                                        >
                                            <img className="blue_img" src={appstorebtn} alt="image" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="ft_inner">
                            <div className="copy_text">
                                <p>© Melbe.app 2023 – {new Date().getFullYear()}. Všechna práva vyhrazena.</p>
                            </div>
                            <ul className="links">
                                <li>
                                    <Link to="#home_section" onClick={e => handleClickHome(e)}>Domů</Link>
                                </li>
                                <li>
                                    <Link to="#pricing_section" onClick={e => handleClickPrice(e)}>Cena</Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/blog-list">Blog</Link>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to="#contact_section" onClick={e => handleClickContact(e)}>Kontakt</Link>
                                </li>
                            </ul>
                            <ul className="links">
                                <li>
                                    <Link to="/vseobecne-podminky-uzivani-aplikace" onClick={e => handleClickHome(e)}>Podminky a pravidla</Link>
                                </li>
                                <li>
                                    <Link to="/zasady-ochrany-osobnich-udaju" onClick={e => handleClickHome(e)}>Ochrana osobních údajů</Link>
                                </li>
                            </ul>
                            <div className="design_by">
                                <p>
                                    <Link to="https://datasession.cz?utm_source=melbe.app&utm_medium=referral&utm_campaign=melbe_contact" target="_blank" >
                                        DataSession s.r.o.
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main
